// Email
export const EMAIL = 'gonzalo.arenas.flores@gmail.com'

// Social Networks
export const SOCIAL_NETS = {
  twitter: 'https://twitter.com/GonzaloArenasF',
  instagram: 'https://www.instagram.com/gonzaloarenasf',
  flickr: 'https://www.flickr.com/people/ozddios/',
  linkedin: 'https://www.linkedin.com/in/gonzaloarenasf',
  github: 'https://github.com/GonzaloArenasF',
  youtube: 'https://www.youtube.com/c/GonzaloArenasFlores',
  facebook: 'https://www.facebook.com/Gonzalo-A-Arenas-Flores-110797213989907'
}

import React from "react";

import Logo from "../../../webkit/logo";

import SocialNetsComponent from "../SocialNets";

import "./index.scss";

const TeaserComponent: React.FC = () => {
  return (
    <div className="TeaserComponent">
      <Logo />
      <ul className="TeaserComponent__items">
        <li>
          Product Manager <code>WIP</code>
        </li>
        <li>Web Developer</li>
        <li>Photographer</li>
        <li>Musician</li>
      </ul>
      <SocialNetsComponent type="simple" iconSize="small" />
      <blockquote>Coming soon 2020/21</blockquote>
    </div>
  );
};

export default TeaserComponent;

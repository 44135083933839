import React from "react";

import './index.scss'

const Logo: React.FC = () => {
  return (
    <div className="GonzaloArenasF-Logo">
      <h1>Gonzalo</h1>
      <h1>Arenas Flores</h1>
    </div>
  );
};

export default Logo;

import React from 'react'

// Pages
import WellcomePage from './pages/Wellcome'

import '../webkit/styles/main.scss'

export default function MainPage(): JSX.Element {
  return (
    <main>
      <WellcomePage />
    </main>
  )
}
import React from 'react';

import './index.scss'

interface IconI {
  name: string
  size: 'small' | 'medium' | 'big'
}

const iconList: any = {
  linkedIn: <i className="fab fa-linkedin-in"></i>,
  twitter: <i className="fab fa-twitter"></i>,
  instagram: <i className="fab fa-instagram"></i>,
  flickr: <i className="fab fa-flickr"></i>,
  github: <i className="fab fa-github"></i>,
  youtube: <i className="fab fa-youtube"></i>,
  facebook: <i className="fab fa-facebook-f"></i>,
  email: <i className="fas fa-envelope"></i>
}

const Icon: React.FC<IconI> = ({ name, size }) => {
  return <span className={`Icon Icon--${size}`}>{iconList[name]}</span>
}

export default Icon
import React from 'react'

import TeaserComponent from '../../components/Teaser'

import './index.scss'

const WellcomePage: React.FC = () => {
  return (
    <section className="WellcomePage">
      <TeaserComponent />
    </section>
  )
}

export default WellcomePage
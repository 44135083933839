import React from "react";

import { SOCIAL_NETS, EMAIL } from "../../../constants";

import Icon from "../../../webkit/icons";

import "./index.scss";

type IconSizeT = "small" | "medium" | "big";

interface SocialNetsI {
  type: "simple" | "list";
  iconSize: IconSizeT;
}

interface SimpleListI {
  iconSize: IconSizeT;
}

const SimpleList: React.FC<SimpleListI> = ({ iconSize }) => {
  return (
    <div className="SocialNets__SimpleList">
      <ul>
        <li>
          <a
            href={SOCIAL_NETS.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter" size={iconSize} />
          </a>
        </li>
        <li>
          <a
            href={SOCIAL_NETS.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="instagram" size={iconSize} />
          </a>
        </li>
        <li>
          <a
            href={SOCIAL_NETS.flickr}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="flickr" size={iconSize} />
          </a>
        </li>
        <li>
          <a
            href={SOCIAL_NETS.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedIn" size={iconSize} />
          </a>
        </li>
        <li>
          <a
            href={SOCIAL_NETS.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="github" size={iconSize} />
          </a>
        </li>
        <li>
          <a
            href={SOCIAL_NETS.youtube}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="youtube" size={iconSize} />
          </a>
        </li>
        <li>
          <a
            href={SOCIAL_NETS.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="facebook" size={iconSize} />
          </a>
        </li>
        <li>
          <a href={`mailto: ${EMAIL}`}>
            <Icon name="email" size={iconSize} />
          </a>
        </li>
      </ul>
    </div>
  );
};

const SocialNetsComponent: React.FC<SocialNetsI> = ({ type, iconSize }) => {
  switch (type) {
    case "simple":
      return <SimpleList iconSize={iconSize} />;

    default:
      return <SimpleList iconSize={iconSize} />;
  }
};

export default SocialNetsComponent;
